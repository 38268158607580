<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="passwordObserver">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Текущий пароль"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|min:6"
                name="текущий пароль"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Введите текущий пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label-for="account-new-password" label="Новый пароль">
              <validation-provider
                rules="required|min:6|password:@подтверждение"
                v-slot="{ errors }"
                name="Новый пароль"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Введите новый пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Поторите новый пароль"
            >
              <validation-provider
                name="подтверждение"
                rules="required|min:6"
                v-slot="{ errors }"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Введите новый пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="saveNewPassword"
            >
              Изменить
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import firebase from "firebase/compat/app";
import { fb, db } from "@/main";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import { required, email, min, url } from "@validations";
import ru from "vee-validate/dist/locale/ru.json";
localize("ru", ru);
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Пароли не совпадают",
});

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      user: null,
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    async saveNewPassword() {
      this.$refs.passwordObserver.validate().then((success) => {
        if (success) {
          this.user = fb.auth().currentUser;
          var credential = firebase.auth.EmailAuthProvider.credential(
            this.user.email,
            this.passwordValueOld
          );

          this.user
            .reauthenticateWithCredential(credential)
            .then(
              function() {
                if (this.newPasswordValue === this.RetypePassword) {
                  this.user
                    .updatePassword(this.newPasswordValue)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: `Успешно!`,
                          icon: "CoffeeIcon",
                          variant: "success",
                          text: `Вы успешно изменили пароль. Теперь вы можете работать спокойно!`,
                        },
                      });
                      this.$router.push("/");
                    })
                    .catch((error) => {
                      console.log(error);
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: `Что-то пошло не так!`,
                          icon: "XIcon",
                          variant: "danger",
                          text: `Возможно неверный пароль или вы использовали вход через другие службы!`,
                        },
                      });
                    });
                }
              }.bind(this)
            )
            .catch(
              function(error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Что-то пошло не так!`,
                    icon: "XIcon",
                    variant: "danger",
                    text: `Возможно неверный пароль или вы использовали вход через другие службы!`,
                  },
                });
              }.bind(this)
            );
        }
      });
    },
  },
  mounted() {
    this.user = fb.auth().currentUser;
  },
};
</script>
